<template>
  <div class="row">
    <div class="col-12" style="text-align: center">
      <editor-menu-bar
        :editor="editor"
        v-slot="{ commands, isActive }"
        style="margin-bottom: 5px"
      >
        <div class="menubar">
          <button
            type="button"
            class="btnEditor"
            :class="{ active: isActive.bold() }"
            @click="commands.bold"
          >
            <i class="fas fa-bold"></i>
          </button>

          <button
            type="button"
            class="btnEditor"
            :class="{ active: isActive.italic() }"
            @click="commands.italic"
          >
            <i class="fas fa-italic"></i>
          </button>

          <button
            class="btnEditor"
            :class="{ active: isActive.strike() }"
            type="button"
            @click="commands.strike"
          >
            <i class="fas fa-strikethrough"></i>
          </button>

          <button
            class="btnEditor"
            type="button"
            :class="{ active: isActive.underline() }"
            @click="commands.underline"
          >
            <i class="fas fa-underline"></i>
          </button>

          <button
            class="btnEditor"
            type="button"
            :class="{ active: isActive.paragraph() }"
            @click="commands.paragraph"
          >
            <i class="fas fa-paragraph"></i>
          </button>

          <button
            class="btnEditor"
            type="button"
            :class="{ active: isActive.heading({ level: 3 }) }"
            @click="commands.heading({ level: 3 })"
          >
            H3
          </button>

          <button
            class="btnEditor"
            type="button"
            :class="{ active: isActive.bullet_list() }"
            @click="commands.bullet_list"
          >
            <i class="fas fa-list"></i>
          </button>

          <button
            class="btnEditor"
            type="button"
            :class="{ active: isActive.ordered_list() }"
            @click="commands.ordered_list"
          >
            <i class="fas fa-list-ol"></i>
          </button>
        </div>
      </editor-menu-bar>
    </div>
    <div class="col-12">
      <editor-content :editor="editor" />
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";

import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  Bold,
  Italic,
  Heading,
  Strike,
  BulletList,
  ListItem,
  OrderedList,
  Underline,
} from "tiptap-extensions";

export default {
  name: "Editor",
  props: ["Editor", "tipoMedidas"],
  components: {
    EditorContent,
    EditorMenuBar,
  },
  beforeDestroy() {
    // Always destroy your editor instance when it's no longer needed
    this.editor.destroy();
  },
  data() {
    return {
      editor: new Editor({
        onUpdate: ({ getHTML }) => {
          // get new content on update
          const newContent = getHTML();
          this.setMedidas({
            tipoMedidas: this.tipoMedidas,
            medidas: newContent,
          });
        },
        extensions: [
          new BulletList(),
          new Heading({ levels: [1, 2, 3] }),
          new ListItem(),
          new OrderedList(),
          new Bold(),
          new Italic(),
          new Strike(),
          new Underline(),
        ],
        content: "",
      }),
    };
  },
  methods: {
    ...mapMutations({
      setMedidas: "contenido/setMedidas",
    }),
  },
};
</script>
<style>
.ProseMirror {
  background: #bbbbbb29;
  border-radius: 10px;
  background: #bbbbbb29;
  padding-top: 10px;
  border-radius: 10px;
  padding-left: 40px;
  padding-right: 20px;
  min-height: 100px;
  padding-bottom: 10px;
}

.btnEditor {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background: none;
  color: #444444;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-right: 5px;
}

.active {
  background: #444444;
  color: white;
}
ul > li {
  list-style: inherit !important;
}

ol > li {
  list-style: inherit !important;
}
</style>